export var SearchList = [
  { labe: '单据编号', code: 'qualityCode', type: 'input' },
  {
    labe: '单据类型',
    code: 'type',
    type: 'select',
    option: [
      { label: '8D', value: '1' },
      { label: 'FA', value: '2' }
    ]
  },
  {
    labe: '问题类型',
    code: 'problemType',
    type: 'select',
    option: [
      { label: '实物问题', value: '1' },
      { label: '交付问题', value: '2' },
      { label: '产能问题', value: '3' },
      { label: '包装问题', value: '4' },
      { label: '其他', value: '5' }
    ]
  },
  { labe: '单据标题', code: 'title', type: 'input' },
  { labe: '物料编码', code: 'materiel', type: 'input' },
  { labe: '公司', code: 'company', type: 'input' },
  {
    labe: '创建日期',
    code: 'createDate',
    type: 'daterange'
  },
  {
    labe: '状态',
    code: 'supplierType',
    type: 'select',
    option: [
      { label: '待我确认', value: 'null' },
      { label: '我已确认', value: '2' },
      { label: '已驳回', value: '3' },
      { label: '已结案', value: '4' }
    ]
  }
]
export var SearchData = {
  qualityCode: '',
  type: '',
  problemType: '',
  title: '',
  materiel: '',
  company: '',
  startTime: '',
  endTime: '',
  status: ''
}
export var tableField = [
  { label: '单据编号', code: 'qualityCode', type: 'input', width: '120' },
  {
    label: '单据类型',
    code: 'type',
    type: 'function',
    width: '120',
    handle: (index, data) => {
      return data.type === 1 ? '8D' : data.type === 2 ? 'FA' : ''
    }
  },
  {
    label: '状态',
    code: 'status',
    type: 'function',
    width: '120',
    handle: (index, data) => {
      // return data.status === 1 ? '待我确认' : data.status === 2 ? '待客户确认' : data.status === 3 ? '已完成' : ''
      return data.supplierType === '' ? '待供应商确认' : data.supplierType === null ? '待供应商确认' : data.supplierType === undefined ? '待供应商确认' : data.supplierType === 2 ? '供应商已确认' : data.supplierType === 3 ? '已驳回' : data.supplierType === 4 ? '已结案' : ''
    }
  },
  /*   { label: '问题来源', code: 'problemSource', type: 'input', width: '140' }, */
  {
    label: '问题类型',
    code: 'problemType',
    type: 'function',
    width: '120',
    handle: (index, data) => {
      return data.problemType === 1 ? '实物问题' : data.problemType === 2 ? '交付问题' : data.problemType === 3 ? '产能问题' : data.problemType === 4 ? '包装问题' : '其他'
    }
  },
  { label: '单据标题', code: 'title', type: 'input', width: '' },
  { label: '公司名称', code: 'companyName', type: 'input', width: '' },
  { label: '物料编号', code: 'materielCode', type: 'input', width: '' },
  { label: '物料描述', code: 'materielName', type: 'input', width: '' },
  {
    label: '重视度',
    code: 'levelImportance',
    type: 'function',
    width: '120',
    handle: (index, data) => {
      return data.levelImportance === 1 ? '极高' : data.levelImportance === 2 ? '高' : data.levelImportance === 3 ? '一般' : ''
    }
  },
  { label: '创建日期', code: 'createDate', type: 'input', width: '' },
  {
    label: '操作',
    code: 'act',
    width: '120px',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      }
    ]
  }
]

export var tableData = [
  {
    code: '11'
  }
]
